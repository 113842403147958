import { initializeApp } from 'firebase/app'
import { getMessaging as getFirebaseMessaging, getToken as getFirebaseToken } from 'firebase/messaging'
import { requestNotifyPermission } from '../../utility/Utils'

const VAPID_KEY = 'BOyN7E5_tHWQNpMbpkh0rlYiBQkJpHCXGXHpE4Rnq4OOViH_IxSqPJTqPuEeKbCsw9DdXj3eIx1-STNarLc0rb0'

class Firebase {
  constructor() {
    this.app = initializeApp({
      apiKey: 'AIzaSyBpmhExCzypnuDExhRVsLxNok6k144zmF8',
      authDomain: 'fi-network.firebaseapp.com',
      projectId: 'fi-network',
      storageBucket: 'fi-network.appspot.com',
      messagingSenderId: '686152420733',
      appId: '1:686152420733:web:881c06d861cf6d2a625014',
      measurementId: 'G-P4SSE5647P'
    })
    this.messaging = getFirebaseMessaging(this.app)
    this.init()
  }

  async init() {
    await requestNotifyPermission()
      .then(async (data) => {
        if (data) {
          const currentToken = await getFirebaseToken(this.messaging, {
            vapidKey: VAPID_KEY
          })
          console.warn({ currentToken })
        }
      })
      .catch(() => console.error('Notification not permission!'))
  }
}

new Firebase()
