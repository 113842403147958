import moment from "moment"

export const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

export const sortCompare = (key) => (a, b) => {
  const fieldA = a[key]
  const fieldB = b[key]

  let comparison = 0
  if (fieldA > fieldB) {
    comparison = 1
  } else if (fieldA < fieldB) {
    comparison = -1
  }
  return comparison
}

export const getRandomInt = (min, max) => {
  if (min > max) {
    const temp = max
    /* eslint-disable no-param-reassign */
    max = min
    min = temp
    /* eslint-enable */
  }

  if (min <= 0) {
    return Math.floor(Math.random() * (max + Math.abs(min) + 1)) + min
  }
  return Math.floor(Math.random() * max) + min
}

export const randomDate = (start, end) => {
  const diff = end.getTime() - start.getTime()
  const newDiff = diff * Math.random()
  const date = new Date(start.getTime() + newDiff)
  return date
}

export const formatPrice = (value,validate = false)=>{
  if(validate && !value) return ''
  if (!value) return 0;
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatDate = (value)=>{
  return moment(value).format("DD/MM/YYYY")
}

export const calculateDateDiff=(toDate, endDate) => {
  // Parse the dates using Moment.js
  let toDateMoment = moment(toDate);
  let endDateMoment = moment(endDate);

  // Calculate the difference in days
  let dateDiff = endDateMoment.diff(toDateMoment, 'days') + 1; // +1 to include both start and end dates

  return dateDiff;
}

export const calculateDateDiffFormatDate=(toDate, endDate) => {
  // Parse the dates using Moment.js
  let toDateMoment = moment.utc(toDate,"DD/MM/YYYY");
  let endDateMoment = moment.utc(endDate,"DD/MM/YYYY");

  // Calculate the difference in days
  let dateDiff = endDateMoment.diff(toDateMoment, 'days') + 1

  return dateDiff;
}

export function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

export function generateContractCode(contractType) {
  // Get the current date and time
  const now = new Date();

  // Format the date and time as YYYYMMDDHHMM
  const formattedDateTime = now.toISOString().slice(0, 19).replace(/[-:T]/g, '').slice(0, 12);

  // Generate a random 4-character hexadecimal string
  const randomHex = Math.floor(Math.random() * 0x10000).toString(16).padStart(4, '0').toUpperCase();

  // Construct the contract code
  const contractCode = `HĐ_${randomHex}${contractType}_${formattedDateTime}`;

  return contractCode;
}


